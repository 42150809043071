import styled from "styled-components";
import { useEffect } from "react";
import { CustomLink } from "../components/customLink";

const MobileHomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 10px 0 0;
`;

const Shards = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 5;
`;

const BatLogo = styled.img`
  width: 80%;
  position: relative;
  z-index: 11;
`;

const Links = styled.div`
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  font-size: 26px;
`;

const Stefan = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  width: 100%;
  height: 30%;
  background-size: 170%;
  background-position: 32% 61%;
  background-image: url("https://res.cloudinary.com/dhrvrcn5g/image/upload/c_crop,w_703,h_543,x_224,y_95/v1743093430/stefan_flttub.jpg");
`;

const Patrick = styled.div`
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 50%);
  width: 50%;
  background-size: 110%;
  background-position: 60% 17%;
  background-image: url("https://res.cloudinary.com/dhrvrcn5g/image/upload/c_crop,w_404,h_1122,x_0,y_52/v1743093431/patrick_mgysgm.jpg");
`;

const Sam = styled.div`
  clip-path: polygon(0% 100%, 0% 50%, 100% 0%, 100% 100%);
  width: 50%;
  background-size: cover;
  background-position: 68% 17%;
  background-image: url("https://res.cloudinary.com/dhrvrcn5g/image/upload/c_crop,w_800,h_1200,x_154,y_0/v1743093431/sam_lwp7es.jpg");
`;

const Lauryn = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 100%);
  width: 50%;
  background-size: 146%;
  background-position: 39% 15%;
  background-image: url("https://res.cloudinary.com/dhrvrcn5g/image/upload/c_crop,w_518,h_1200,x_216,y_0/v1743093431/lg_msvmuo.jpg");
`;

const Luke = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 75%);
  width: 50%;
  background-size: cover;
  background-position: 37% 136%;
  background-image: url("https://res.cloudinary.com/dhrvrcn5g/image/upload/c_crop,w_486,h_804,x_51,y_156/v1743093431/luke3_cbwxjp.jpg");
`;
const Spencer = styled.div`
  clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0% 100%);
  width: 50%;
  background-size: cover;
  background-position: 55% 15%;
  background-image: url("https://res.cloudinary.com/dhrvrcn5g/image/upload/c_crop,w_314,h_614,x_500,y_0/v1743093431/spencer_yfxagn.jpg");
`;

const Eric = styled.div`
  clip-path: polygon(0% 0%, 100% 25%, 100% 100%, 0% 100%);
  width: 50%;
  background-size: 183%;
  background-position: 52% 94%;
  background-image: url("https://res.cloudinary.com/dhrvrcn5g/image/upload/c_crop,w_536,h_932,x_108,y_20/v1743093431/eric2_ijeont.jpg");
`;

const ShardRow = styled.div`
  display: flex;
  height: 40%;
  gap: 125px;
  position: relative;
  top: -30%;
  margin-top: 5px;
`;

const BottomShardRow = styled(ShardRow)`
  top: -40%;
`;

const ImageWrapper = styled.div`
  margin-top: 90px;
  position: relative; /* Ensures children position relative to this */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundCircle = styled.div`
  position: absolute;
  width: 63%; /* Slightly larger than image */
  height: 96%;
  background-color: #393737; /* Change to your desired color */
  border-radius: 50%;
  z-index: 5; /* Places it behind the image */
`;

const MobileHomeBackground = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: #393737;
`;

export default function MobileHomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const useShards = true;

  return (
    <>
      {useShards && (
        <Shards>
          <Stefan />
          <ShardRow>
            <Patrick />
            <Sam />
          </ShardRow>
          <ShardRow>
            <Lauryn />
            <Luke />
          </ShardRow>
          <BottomShardRow>
            <Spencer />
            <Eric />
          </BottomShardRow>
        </Shards>
      )}
      {!useShards && <MobileHomeBackground />}

      <MobileHomePageContainer>
        <ImageWrapper>
          <BackgroundCircle />
          <BatLogo src="/white-bat-logo.svg" />
        </ImageWrapper>
        <Links>
          <span>
            <CustomLink to="/about">About</CustomLink>
          </span>
          <span>
            <CustomLink to="/video">Video</CustomLink>
          </span>
          <span>
            <CustomLink to="/contact">Contact</CustomLink>
          </span>
          <span>
            <CustomLink to="/donate">Donate</CustomLink>
          </span>
          <div className="social-footer-icons">
            <a href="https://www.facebook.com/BatCityBrassBand" target="blank">
              <i className="fa-brands fa-facebook" aria-hidden="true" />
            </a>
            <a
              href="https://www.instagram.com/batcitybrassband/?hl=en"
              target="blank"
            >
              <i className="fa-brands fa-instagram" aria-hidden="true" />
            </a>
          </div>
        </Links>
      </MobileHomePageContainer>
    </>
  );
}
